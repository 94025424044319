import { ActionContext } from "vuex";

import UserApiServices from "@/core/services/User";

interface State {
  list: Array<any>;
  banner: Boolean
}

const state: State = {
  list: [],
  banner: false
};

const getters = {
  getList: (state: State) => state.list,
  getBanner: (state: State) => state.banner
};

const actions = {
  async fetch(context: ActionContext<State, any>, state: State) {
    try {
      const resp = await UserApiServices.getUserInfo();
      // console.log(resp)
      context.commit("SET_LIST", { ...resp, credit: 0 });
      context.commit("SET_BANNER", resp.data.banner!="" ? true : false);
    } catch (error: any) {
      if (process.env.NODE_ENV === "development") {
        console.log("error", error);
      }
    }
  },
};

const mutations = {
  SET_LIST(state: State, data: Array<any>) {
    state.list = data;
    
  },

  SET_BANNER(state: State, statusBanner: Boolean) {
    state.banner = statusBanner;
  },

  UPDATE_CREDIT(state: State, data: Array<any>) {
    state.list = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
